import { useEffect, useRef, useState } from 'react';
import './BetsPage.scss';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { beautifyDbName, displayAmount, currencySymbols } from '../../helpers';

const BetsPage = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(params.page || 1);
  const [isTableHeaderSticky, setIsTableHeaderSticky] = useState(false);

  const betsData = useSelector((state) => state.app.betsData);
  const winsData = useSelector((state) => state.app.winsData);
  const partners = useSelector((state) => state.app.partners);
  const games = useSelector((state) => state.app.games);

  useEffect(() => {
    if (params.page > 0) {
      setPage(parseInt(params.page));
      
      const tableContainerEl = document.querySelector('.table-container');

      tableContainerEl.scrollTo(tableContainerEl.scrollLeft, 0);
    }
  }, [params.page]);

  const itemsPerPage = 50;
  const totalPages = Math.ceil(betsData.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const currentItems = betsData.slice(startIndex, startIndex + itemsPerPage);

  const visiblePages = (() => {
    if (page <= 2) return [1, 2, 3].filter((p) => p <= totalPages); // Near the start
    if (page >= totalPages - 1) return [totalPages - 2, totalPages - 1, totalPages].filter((p) => p > 0); // Near the end
    return [page - 1, page, page + 1]; // In the middle
  })();

  const goToPage = (targetPage) => {
    if (targetPage > 0 && targetPage <= totalPages) {
      navigate(`/bets/${targetPage}`);
    }
  };

  return (
    <div className="BetsPage">
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Bet ID</th>
              <th>Player ID</th>
              <th>Partner</th>
              <th>Game</th>
              <th>Bet Amount</th>
              <th>Win Amount</th>
              <th>Result<br /><span className="fs-xs">Player</span></th>
              <th>Revenue<br /><span className="fs-xs">Casino</span></th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {
              currentItems.map((bet, i) => {
                const partner = partners.find((p) => p.id === bet.partner_id);
                const game = games.find((g) => g.key === bet.game_id);
                const win = winsData.find((w) => w.bet_id === bet.id);
                let result = parseFloat(-bet.amount);

                if (win) {
                  result += win.amount;
                }

                return (
                  <tr
                    key={i}
                  >
                    <td>{bet.id}</td>
                    <td>{bet.player_id}</td>
                    <td>{beautifyDbName(partner.name)}</td>
                    <td>{beautifyDbName(game.key)}</td>
                    <td className="fs-md">{displayAmount(bet.amount, bet.currency)}</td>
                    <td className="fs-md">{win ? displayAmount(win.amount, win.currency) : displayAmount(0, bet.currency)}</td>
                    <td className={`fs-md${(result > 0 ? ' green' : ' red')}`}>{(result > 0 ? '+' : '-')}{displayAmount(Math.abs(result), bet.currency)}</td>
                    <td className={`fs-md${(result > 0 ? ' red fs-sm' : ' green fw-bold')}`}>{(result > 0 ? '-' : '+')}{displayAmount(Math.abs(result), bet.currency)}</td>
                    <td>{bet.date} {bet.time}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>

      <div className="pagination">
        {page > 1 && <button onClick={() => goToPage(page - 1)}>Previous</button>}
        {visiblePages.map((p) => (
          <button
            key={p}
            onClick={() => goToPage(p)}
            className={page === p ? "active" : ""}
          >
            {p}
          </button>
        ))}
        {page < totalPages && <button onClick={() => goToPage(page + 1)}>Next</button>}
      </div>
    </div>
  );
};

export default BetsPage;
