import { useSelector } from 'react-redux';
import './SettingsPage.scss';
import { useState } from 'react';
import request from '../../request';

const SettingsPage = (props) => {
  const [gameSelected, setGameSelected] = useState('');
  const [modeSelected, setModeSelected] = useState('');

  const games = useSelector((state) => state.app.games);

  const changeReelValues = (reelIndex) => {
    const input = window.prompt('Paste array of values');
    let values = null;

    try {
      values = JSON.parse(input);
    } catch (err) {
      alert('Invalid input. Input must look like [1, 5, 10, 3]');
    }
    
    if (values && values.length) {
      request
        .post(`set-reel-values`, {
          game: gameSelected,
          reelIndex,
          values,
          mode: modeSelected,
        })
        .then((response) => {
          if (response.data.status === 'ok') {
            alert('Reel values changed successfuly');
          } else if (response.data.status === 'error') {
            alert(response.data.error);
          }
        })
        .catch((err) => {
          alert(`Error: ${err}`);
        });
    }
  };

  return (
    <div className="SettingsPage">
      <div className="reels-settings">
        <h1>Reels Settings</h1>

        <select
          value={gameSelected}
          onChange={(e) => setGameSelected(e.target.value)}
        >
          <option value="">Select Game</option>
          {
            games.map((game, i) => {
              return (
                <option key={i} value={game.id}>{game.key}</option>
              );
            })
          }
        </select>

        <select
          value={modeSelected}
          onChange={(e) => setModeSelected(e.target.value)}
        >
          <option value="normal">Normal Game</option>
          <option value="bonus">Bonus Game</option>
          <option value="bonus-buy">Bonus Buy Game</option>
        </select>

        <div className="reels">
          {
            gameSelected && Array.from(Array(5)).map((n, i) => {
              return (
                <div
                  key={i}
                  onClick={() => changeReelValues(i + 1)}
                >
                  <span>Click to change values</span>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;
