const formatNumber = (number) => {
  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

const beautifyDbName = (title) => {
  return title
      .toLowerCase()
      .split(/[\s\-]+/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
};

const currencySymbols = {
  USD: '$',
  EUR: '€',
  RUB: '₽',
};

function displayAmount(amount, currency) {
  const currencyFormats = {
      USD: (formattedAmount, symbol) => `${symbol}${formattedAmount}`,
      EUR: (formattedAmount, symbol) => `${formattedAmount} ${symbol}`,
      RUB: (formattedAmount, symbol) => `${formattedAmount} ${symbol}`,
  };

  const currencySymbols = {
      EUR: '€',
      USD: '$',
      RUB: '₽',
  };

  if (typeof amount !== 'number' || isNaN(amount)) {
      throw new Error('Invalid amount. Please provide a valid number.');
  }

  if (!currencyFormats[currency] || !currencySymbols[currency]) {
      throw new Error('Invalid currency. Use "EUR", "USD", or "RUB".');
  }

  const formattedAmount = amount
      .toFixed(2)
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return currencyFormats[currency](formattedAmount, currencySymbols[currency]);
}

export {
  formatNumber,
  beautifyDbName,
  currencySymbols,
  displayAmount,
};
